@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

/* html {
  cursor: none;
}
 */

@font-face {
  font-family: "Druk Text Wide Trial Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Druk Text Wide Trial Bold"),
    url("../src/font/DrukTextWide-Bold-Trial.woff") format("woff");
}

@font-face {
  font-family: "Liberator";
  font-style: normal;
  font-weight: normal;
  src: local("Liberator"), url("../src/font/Liberator.ttf") format("truetype");
}

@font-face {
  font-family: "GT-Haptik-Regular";
  font-style: normal;
  font-weight: normal;
  src: local("GT Haptik Regular"),
    url("../src/font/GT-Haptik-Regular.woff2") format("woff2");
}

#info_nft{
  text-align: center;
}
strong {

  font-weight: bolder;
}

#container_background {

 
}

.row-title-text {
  font-size: 2.5rem; /* 48px */
  line-height: 1;
  font-family: "Druk Text Wide Trial Bold";
  padding-left: 55px;
  padding-bottom: 22px;
}
.icon-wrapper {
  font-size: 3rem;
}

#hero-title {
  color: #fdf7e4;
  font-family: "Liberator";

  font-weight: 700;
  line-height: 80px;
  letter-spacing: 0em;
  text-align: left;
}

.separator {
  height: 10px;
  background-color: red;
  border: none;
}
#hero-subtitle {
  font-family: "Druk Text Wide Trial Bold";
  /*   font-size: 120px; */
  font-weight: 900;
  color: #0000;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fdf7e4;
  letter-spacing: 0em;
  text-align: left;
  width: 30%;
  //margin-top: 80px;
}

#sezione-collezione {
  font-family: "Druk Text Wide Trial Bold";
}

.Druk-Text {
  font-family: "Druk Text Wide Trial Bold";
}

.Haptik {
  /*  font-family: "GT Haptik Trial" */
  font-family: "GT-Haptik-Regular";
}

.Liberator {
  font-family: "Liberator";
}

.numero {
  font-family: "Druk Text Wide Trial Bold";
  color: #0000;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fdf7e4;
}

.vantaggio-benefit {
  font-family: "Druk Text Wide Trial Bold";
}

.styles_icon-wrapper__2cftw {
  top: 40px !important;
}

.nft-card-title {
  font-family: "Druk Text Wide Trial Bold";
  color: #fdf7e4;

  font-size: 16px;
}

.row-title-text {
  padding-left: 0%;
}

.roundedShadow {
  /*       filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
 */ /*       border-radius: 100%;*/
  box-shadow: 20px -80px 180px 20px #1a1a1a;
}

/* 
    .marquee-react{

    
    } */

/*   .marquee {
  height:100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.marquee div {
  display: block;
  width: 200%;
  height: 100%;
  position: absolute;
  overflow: hidden;

  animation: marquee 10s linear infinite;
}

.marquee span {
  float: left;
  width: 50%;
  font-family: 'Liberator';
}

@keyframes marquee {
  0% { left: 0; }
  100% { left: -100%; }
} */

.form_body {
  height: 100% !important;
}
.img {
  height: 250px;
}

.nav-link:hover {
  cursor: pointer;
}
.nav-link.active {
  text-decoration: underline;
  font-weight: 700;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

@media (prefers-reduced-motion: no-preference) {
}

.card-container {
}
.styles_faq-row-wrapper__3vA1D {
  border-top: 1px solid #ccc;
}

.styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c {
  padding: 15px 0;
}
.bold {
  font-weight: 600;
}

.small {
  font-size: 12px;
}
.contract-info {
  color: #fdf7e4;
  font-family: "Druk Text Wide Trial Bold";
  text-transform: capitalize;
}

body {
  padding: 0;
  justify-content: center;
  align-items: center;
  background-color: #1a1a1a;
  color: #fff;
}

.container {
  position: relative;
  width: 100%;
  height: 50vh;
}

.box {
  position: absolute;
  width: 100px;
  height: 100px;
  pointer-events: none;
  z-index: 2;
  background-size: cover;
  background-position: center;
}

.box.active {
  transform: scale(1);
}

.content {
  z-index: 1;
  color: #fff;
  text-align: center;
}

.prova {
  font-size: 90px;
  margin: 0;
  cursor: default;
  text-transform: uppercase;

  font-weight: 900;
  text-align: center;
}

/* custom-cursor */
.cursor {
  width: 45px;
  height: 45px;
  background: #f7f7f7;
  border-radius: 100%;
  position: fixed;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 9999;
  mix-blend-mode: difference;
  transition: 450ms ease;
  transition-property: border, opacity, background-color, transform,
    mix-blend-mode;
}

.cursor--hidden {
  opacity: 0;
}
.cursor--clicked {
  transform: translate(-50%, -50%) scale(0.9);
  background-color: #fefefe;
}
.cursor--link-hovered {
  transform: translate(-50%, -50%) scale(2);

  border: 1px solid #f7f7f7;
}

.Star-Wars-Text {
  font-family: "Star Jedi";
  text-shadow: 3px 3px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
    -1px 1px 0 #000, 1px 1px 0 #000;
  color: #ffe348;
  text-transform: uppercase;
  perspective: 800px;
  transform-style: preserve-3d;
}

.center {
}

.center__text {
  margin: auto;

  text-transform: uppercase;
}

.glitch {
  position: relative;
  color: white;
  mix-blend-mode: lighten;

  &:before,
  &:after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    width: 100%;
    background: black;
    clip: rect(0, 0, 0, 0);
  }

  &:before {
    left: -1px;
    text-shadow: 1px 0 rgba(red, 0.7);
  }

  &:after {
    left: 1px;
    text-shadow: -1px 0 rgba(blue, 0.7);
  }

  &:hover {
    &:before {
      text-shadow: 4px 0 rgba(red, 0.7);
      animation: glitch-loop-1 0.8s infinite ease-in-out alternate-reverse;
    }
    &:after {
      text-shadow: -5px 0 rgba(blue, 0.7);
      animation: glitch-loop-2 0.8s infinite ease-in-out alternate-reverse;
    }
  }
}

@keyframes glitch-loop-1 {
  0% {
    clip: rect(111px, 9999px, 40px, 0);
  }
  25% {
    clip: rect(100x, 9999px, 120px, 0);
  }
  50% {
    clip: rect(133x, 9999px, 66px, 0);
  }
  75% {
    clip: rect(119px, 9999px, 152px, 0);
  }
  100% {
    clip: rect(111px, 9999px, 202px, 0);
  }
}

@keyframes glitch-loop-2 {
  0% {
    top: -1px;
    left: 1px;
    clip: rect(89px, 9999px, 140px, 0);
  }
  25% {
    top: -6px;
    left: 4px;
    clip: rect(111px, 9999px, 45px, 0);
  }
  50% {
    top: -3px;
    left: 2px;
    clip: rect(90x, 9999px, 43x, 0);
  }
  75% {
    top: 0px;
    left: -4px;
    clip: rect(122px, 9999px, 100px, 0);
  }
  100% {
    top: -1px;
    left: -1px;
    clip: rect(60px, 9999px, 195px, 0);
  }
}

$color1: red;
$color2: blue;

.text-effect {
  overflow: hidden;
  position: relative;

  // The blend modes mute the colors, so let's bump them up
  filter: contrast(110%) brightness(190%);
}

.neon {
  position: relative;

  // Only works on a black background, sorry
  background: black;

  // The actual text inside the div is superfluous, only data-text matters. The text is added so you can easily edit the preview
  color: transparent;

  &::before,
  &::after {
    // Add two copies of the text on their own layer
    content: attr(data-text);

    // White, so we can paint them later
    color: white;

    // And blur them to create the neon effect with the blend-mode below
    filter: blur(0.02em);

    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  // Set the top copy to difference. This creates the knock-out effect with a bit of glow
  &::after {
    mix-blend-mode: difference;
  }
}

// Position our two effect layers
.gradient,
.spotlight {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  z-index: 10;
}

// Add the colors
.gradient {
  background: linear-gradient(45deg, $color1, $color2);

  // Multiply mode will paint only the white text
  mix-blend-mode: multiply;
}

.spotlight {
  animation: light 5s infinite linear;

  // These are the lights, color-dodge needs a black bg for optimum effect
  background: radial-gradient(circle, white, transparent 25%) 0 0 / 25% 25%,
    radial-gradient(circle, white, black 25%) 50% 50% / 12.5% 12.5%;
  top: -100%;
  left: -100%;

  // Color dodge gives the lights that shimmer effect
  mix-blend-mode: color-dodge;
}

@keyframes light {
  100% {
    transform: translate3d(50%, 50%, 0);
  }
}

//=== Pen styling, ignore

// Style text
.neon {
  font: 700 200px "Lato", sans-serif;
  text-transform: uppercase;
  text-align: center;
  margin: 0;

  &:focus {
    outline: none;
    border: 1px dotted white;
  }
}

.marquee-container {
  display: flex !important;
  flex-direction: row !important;
  position: relative;
  width: var(--width);
  transform: var(--transform);
}

@media screen and (max-width: 768px) {
  .row-title-text {
    font-size: 17px;
  }

  .nft-card-title {
    font-family: "Druk Text Wide Trial Bold";

    font-size: 10px;
  }

  #discover-button {
    font-size: 10px;
  }
}
